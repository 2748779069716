body {
  margin: 0;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.search {
  width: 50%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 600px) {
  .search {
    width: 80%;
  }
}
